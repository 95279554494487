/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx, css } from "@emotion/react"
import Page from "../../layouts/page"
import { MDXRenderer } from "gatsby-plugin-mdx";


let datesStyles = css`
color: #777;
`;

const blogStyles = css`
margin: 10px;
display: flex;
justify-content: center;
`

const contentStyles = css`
width: 50%;
text-align: left;
`

const headerTitle = css`
margin-bottom: 10px;
font-size: 3em;
`

export default function Template({
  data,
}: { data: Record<string, any> }) {
  const { mdx } = data
  const { frontmatter, body } = mdx;

  return (
    <Page title={frontmatter.title} description={mdx.excerpt} author={frontmatter.author.name}>
        <div css={blogStyles}>
            <div css={contentStyles}>
                <h1 css={headerTitle}>{frontmatter.title}</h1>
                <span css={datesStyles}>{frontmatter.date} &#8226; {frontmatter.author.name}</span>
                <MDXRenderer>{body}</MDXRenderer>
            </div>
        </div>
    </Page >
  )
}

export const pageQuery = graphql`
query mdxPage($id: String) {
    mdx (id: { eq: $id }) {
      body
      excerpt(pruneLength: 220)
      frontmatter {
        title
        slug
        date
        author {
          name
        }
      }
    }
  }
`
